.dashboard-container {
    padding: 15px 25px;
    .dashboard {
        &-detail {
            border-radius: 24px;
            padding: 10px 25px;
            margin-bottom: 10px;
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &-label {
            font-weight: 500;
            font-size: 18px;
            line-height: 17px;
            color: #FCFCFC;
            min-height: 30px;
            line-height: 20px;

        }

        &-value {
            font-weight: 600;
            font-size: 20px;
            line-height: 25px;
            color: #FCFCFC;        
        }
    }
}