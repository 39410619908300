.route-container {
    .nav-link {
        color: #91919F;
        background: #000;
    }

    .nav-link.active {
        background-color: #272727;
        color: #fff;
        border: 1px solid #272727;
    }

    .route {
        border-bottom: 1px solid #444;
        padding: 15px;
        margin-bottom: 10px;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: 5px 3px;
                background: #fff;
                border-radius: 10px;
            }
        }

        &-location {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #fff;

        }

        &-time {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }


        &-link {
            margin-top: 5px;

            button {
                border-radius: 20px;
                line-height: 1rem !important;
            }
        }

    }

    .route-loads {
        .load {
            padding: 10px;
            background: #272727;
            margin-bottom: 10px;

            &-location {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #fff;
            }

            &-transporter {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #fff;
            }

            &-time {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #91919F;
            }

            &-weight {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #fff;
            }

            &-rate {
                font-weight: 400;
                font-size: 13px;
                line-height: 16px;
                color: #fff;
            }
        }
    }

    .payments-container {
        .payment {
            padding: 10px;
            background: #272727;
            margin-bottom: 10px;

            &-date {
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                color: #fff;
                margin-bottom: 5px;
            }

            &-icon {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    padding: 5px 3px;
                    background: #fff;
                    border-radius: 10px;
                }
            }

            &-category {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #fff;

            }

            &-description {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #91919F;
                min-height: 30px;
            }

            &-amount {
                font-weight: 600;
                font-size: 16px;
                line-height: 19.36px;
                color: #91919F;
                min-height: 25px;

                &.debit {
                    color: red;
                }

                &.credit {
                    color: #00A86B
                }
            }

            &-time {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #91919F;
            }

        }
    }

    .route-analysis {
        padding: 10px;
        background: #272727;
        margin-bottom: 10px;

        .asset {
            &-type {
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                color: #fff;
            }

            &-name {
                font-weight: 500;
                font-size: 13px;
                line-height: 16px;
                color: #91919F;
            }

        }
    }
}