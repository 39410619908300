#protected-layout {
  .footer {
    display: relative;
  }

  .menu {
    border-top: 1px solid #444;
    width: 100%;
    background: #000;
    position: fixed;
    bottom: 0px;
    display: flex;

    &-item {
      color: #D9D9D9;
      padding: 8px 15px 25px 15px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 20%;
      max-width: 20%;
    }

    span {
      display: block;
      font-weight: 500;
      font-size: 10px;
      margin-top: 2px;
      color: #C6C6C6;
    }

    div {
      color: #D9D9D9;
      padding: 8px 15px 25px 15px;
      text-decoration: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  .more-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    right: 10px;
    bottom: 80px;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #000;
      color: #fff;
      text-decoration: none;
      width: 53px;
      padding: 10px 5px;
      border-radius: 50%;
      margin-bottom: 10px;
      box-shadow: 0 0px 2px 0 #fff;

      span {
        margin-top: 3px;
        font-size: 8px;
      }
    }
  }

  .add-options {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;

    a {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #000;
      color: #fff;
      text-decoration: none;
      width: 53px;
      margin: 5px;
      padding: 10px 5px;
      border-radius: 50%;
      margin-bottom: 10px;
      box-shadow: 0 0px 2px 0 #fff;
      span {
        margin-top: 3px;
        font-size: 8px;
      }
    }
  }
}