.salary-container {

    .salary-analysis {
        margin-bottom: 10px;

        &-tile {
            width: 100%;
            padding: 5px;
            border-radius: 13px
        }

        &-label {
            font-size: 13px;
        }
    }

    .nav-link {
        color: #91919F;
        background: #000;
    }

    .nav-link.active {
        background-color: #272727;
        color: #fff;
        border: 1px solid #272727;
    }

    #scrollable-paid-salary-div,
    #scrollable-salary-div {
        min-height: calc(100vh - 240px);
        max-height: calc(100vh - 240px);
        overflow: hidden;
        overflow-y: auto;
    }

    .salary {
        padding: 10px;
        background: #272727;
        margin-bottom: 10px;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: 5px 3px;
                background: #fff;
                border-radius: 10px;
            }
        }

        &-month {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #fff;

        }

        &-description {
            font-weight: 500;
            font-size: 13px;
            color: #91919F;
            margin: 3px 0px;
        }

        &-days {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }

        &-final {
            font-weight: 600;
            font-size: 16px;
            margin-top: 5px;
            color: #fff;
        }

        &-monthly {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }

    }
}