.contacts-container {
    .contact {
        padding: 10px;
        background: #272727;
        margin-bottom: 10px;

        &-date {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #fff;
            margin-bottom: 5px;
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: 5px 3px;
                background: #fff;
                border-radius: 10px;
            }
        }

        &-name {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #fff;

        }

        &-address {
            font-weight: 500;
            font-size: 13px;
            color: #91919F;
            margin: 3px 0px;
        }

        &-mobile {
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #fff;
            a{
                text-decoration: none;
                color: #fff;
            }
        }

        &-city {
            font-weight: 400;
            font-size: 10px;
            margin-top: 5px;
            color: #91919F;
        }
        &-state {
            font-weight: 600;
            font-size: 16px;
            margin-top: 5px;
            color: #91919F;
        }

        &-status {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }

    }
}