.routes-container {
    .route {
        padding: 10px;
        background: #272727;
        margin-bottom: 10px;

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: 5px 3px;
                background: #fff;
                border-radius: 10px;
            }
        }

        &-location {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #fff;

        }

        &-time {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }


        &-link {
            margin-top: 5px;

            button {
                border-radius: 20px;
                line-height: 1rem !important;
            }
        }

    }
}