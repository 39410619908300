.payments-container {
    .payment {
        padding: 10px;
        background: #272727;
        margin-bottom: 10px;

        &-date {
            font-weight: 600;
            font-size: 18px;
            line-height: 22px;
            color: #fff;
            margin-bottom: 5px;
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                padding: 5px 3px;
                background: #fff;
                border-radius: 10px;
            }
        }

        &-category {
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            color: #fff;
            
        }

        &-description {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
            min-height: 30px;
        }

        &-amount {
            font-weight: 600;
            font-size: 16px;
            line-height: 19.36px;
            color: #91919F;
            min-height: 25px;

            &.debit {
                color: red;
            }

            &.credit {
                color: #00A86B
            }
        }

        &-time {
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #91919F;
        }

    }
}